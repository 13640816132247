html {
  height: -webkit-fill-available;
}

body {
  height: 100vh;
  width: 100vw;
  background: #5eb2ff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* prevent tap highlight color / shadow */
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
}

.App-header {
  width: 90vw;
  font-family: "Poppins";
  font-style: normal;
  background: #5eb2ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.App-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  max-width: 744px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin: 1px;
}

.logo {
  width: 50%;
  margin-bottom: 20px;
}

.App-body-int {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

textarea::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
}
textarea::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #aeaeb2;
}
textarea::-webkit-scrollbar-track {
  /* Background */
  background: #e5e5ea;
  margin: 15px;
  left: -10px;
}

.App-link {
  color: #61dafb;
}

.Buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Invite-Link {
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 200px;
  height: 56px;
  user-select: none;
  background: linear-gradient(180deg, #288bfe 0%, #0c6fe2 100%);
  border-radius: 16px;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  margin: 20px 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.Invite-Link.disabled {
  opacity: 0.4;
  cursor: default;
}

.Invite-Link:hover {
  background: linear-gradient(180deg, #3995ff 0%, #0c6fe2 100%);
}

.Invite-Link.disabled:hover {
  background: linear-gradient(180deg, #288bfe 0%, #0c6fe2 100%);
}

.Invite-Link:active {
  background: linear-gradient(180deg, #0c6fe2 0%, #288bfe 100%);
}

.Invite-Link.disabled:active {
  background: linear-gradient(180deg, #288bfe 0%, #0c6fe2 100%);
}

.Clear-Link {
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 200px;
  height: 56px;
  background: #d1d1d6;
  border-radius: 16px;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.Clear-Link:hover {
  background-color: #d8d8dd;
}

.Clear-Link:active {
  background-color: #c6c6cb;
}

.Seperator {
  margin-top: 20px;
}

.Links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.Links a {
  font-size: 12px;
  color: #0C6FE2;
  text-decoration: none;
  font-weight: 500;
}

span {
  background-color: rgba(0, 0, 0, 0);
}

h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-block-end: 0;
}

h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-block-start: 10px;
  margin-block-end: 25px;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #636366;
}

.Text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #8e8e93;
  margin-top: 10px;
  margin-bottom: 20px;
}
