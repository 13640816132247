.textarea-div {
  display: flex;
  width: 100%;
  height: 130px;
}

textarea {
  width: 100%;
  display: block;
  overflow-y: auto;
  background: #f2f2f7;
  border: none;
  border-radius: 16px;
  padding: 20px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #58585c;

  outline: none;
  resize: none;
}

textarea::placeholder {
  opacity: 0.5;
  user-select: none;
}
