/* Add this CSS to your existing App.css file */
.star-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 40px 0;
  }
  
  .star-rating svg {
    width: 36px; /* Increase the size of the stars */
    height: 36px; /* Increase the size of the stars */
  }
  
  .star-rating div {
    padding: 0 10px; /* Add spacing between the stars */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .star-rating .filled {
    fill: #FFD700; /* Yellow color */
  }
  
  .star-rating .unfilled {
    fill: #ccc; /* Light grey for unfilled stars */
  }
  